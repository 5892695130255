import React, { useEffect, useState } from 'react'
import Helpers from '../../../Config/Helpers';
import axios from 'axios';

function PublishHeaderDropdown({ data }) {
    const [isPublish, setIsPublish] = useState(data.is_header);

    useEffect(() => {
        setIsPublish(data.is_header);
    }, [data.is_header]);

    const handleStatusChange = async (e) => {
        const newStatus = e.target.checked ? 1 : 0;

        try {
            const response = await axios.post(`${Helpers.apiUrl}update-header-status/${data.slug}`, {
                is_header: newStatus,
            }, Helpers.authHeaders);

            if (response.status === 200) {
                setIsPublish(newStatus);
                Helpers.toast("success", "Status updated successfully");
            } else {
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            Helpers.toast("error", "Failed to update status");
        }
    };

    return (
        <>
            {/* <input
              type="checkbox"
              checked={parseInt(isPublish) === 1} 
              onChange={handleStatusChange} 
              className="outline-none p-2 rounded bg-[#f9f9f9]"
            />
            <label className="ml-2">
              {parseInt(isPublish) === 1 ? 'Publish' : 'Inactive'} 
            </label> */}
            <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" class="sr-only peer"
                    checked={parseInt(isPublish) === 1}
                    onChange={handleStatusChange} />
                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{parseInt(isPublish) === 1 ? 'Publish' : 'Inactive'} </span>
            </label>
        </>
    );
}




export default PublishHeaderDropdown
