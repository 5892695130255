import React from "react";

function MobileWindowDownload({ isDarkMode }) {

  return (
    <div>
      <div className='block lg:hidden bg-background text-text text-center py-10 px-10'>
                <h1>Download Software For</h1>
                <div className='flex justify-around'>
                    <div className='block align-items-center text-center py-5'>
                        <img src={isDarkMode ? 'assets/windowslogo.png ' : 'assets/lightwindows.png '} className=' my-1 align-items-center pl-[25%]' />
                        <p className='text-xs text-gray-500'>Windows</p>
                    </div>
                    <div className='block align-items-center text-center py-5'>
                        <img src={isDarkMode ? 'assets/applelogo.png ' : 'assets/lightapple.png '} className=' my-1 align-items-center pl-[25%]' />
                        <p className='text-xs text-gray-500'>Mac</p>
                    </div>
                    <div className='block align-items-center text-center py-5'>
                        <img src={isDarkMode ? 'assets/linuxlogo.png ' : 'assets/lightlinux.png '} className=' my-1 align-items-center pl-[25%]' />
                        <p className='text-xs text-gray-500'>Linux</p>
                    </div>
                </div>
            </div> 
    </div>
  );
}

export default MobileWindowDownload;
